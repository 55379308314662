import axios from 'axios';
import { get, isEmpty } from 'lodash';
import { buildURL, Urls } from './apiUrls';
import { removeCookie, setCookie, removeAppCookies } from "./manageCookies";
import { getAuthHeaderToken, loginHandler } from './OktaUtility';
import { generateUUID, getUserInfo, hasOpenAuthToken, isLocalhost, isOKTAStateAndNonceExists, isOKTAStorageAvailable, setRedirectUrl, cacheManager, datadogLogger, DATADOG_LOG_TYPE, clearSession } from './utilCommon';
import { cookieKeysEnum } from '../models/common.models';
import { PublicUrls } from 'models/navigation.models';
import { USER_INFO, IDENTITY_DELEGATION_FLAG } from './constant';

//TODO : Open Auth call process variable.
let IsOpenAuthCallProcess = false;
//TODO : Define Cache request variable.
let cacheRequest = [];

// TODO : Cached all Http calls which one failed
export const onPushCacheRequestHandler = (cb) => {
    cb && cacheRequest.push(cb);
}

// TODO : Send token handler while all http calls failed
export const onSendTokenHandler = (token) => {
    cacheRequest.map(cb => cb(token));
}

// TODO : Request config returns on select auth urls.
export const returnConfigOpenAuthUrl = (config, requestUrl) => {
    // TODO : Validate request Url is without Auth Url.
    if (requestUrl.pathname.includes(Urls.OKTAOpenAuth)
        || requestUrl.pathname.includes(Urls.MemberLogin)
        || requestUrl.pathname.includes(Urls.flagFeature)) {
        return config;
    }
}

// TODO : Check is Current call is member login.
export const isMemberLoginCall = (config) => {
    const { url } = config;
    if (url && url.includes(Urls.MemberLogin)) {
        return true;
    }
    return false;
}

// TODO : Validate User Authentication.
export const isUserAuthentication = () => {
    const userInfo = getUserInfo();
    if (isEmpty(userInfo)) {
        return false;
    }
    return true;
}

// TODO : Check to allow Open auth Request.
export const allowOpenAuthRequest = (requestUrl) => {
    return !hasOpenAuthToken() && !isOKTAStorageAvailable() && !IsOpenAuthCallProcess && !requestUrl.pathname.includes(Urls.MemberLogin)
}

// TODO : Verify Open Auth token expiration in every APIs.
export const validateOpenAuth = async (config) => {
    let authToken = await getAuthHeaderToken();
    config.headers['s-origin'] = window.location.origin;
    let requestUrl = new URL(config.url);
    let openAuthUrls = returnConfigOpenAuthUrl(config, requestUrl);
    let delegation = cacheManager.getItem(USER_INFO) ? get(JSON.parse(cacheManager.getItem(USER_INFO)), IDENTITY_DELEGATION_FLAG) : null;
    let IdentityDelegationUserId = window.btoa(delegation?.proxyMemberUserId);
    if (isLocalhost()) {
        config.headers['OKTA_AUTHORIZED'] = isOKTAStateAndNonceExists();
    }
    // TODO : Check users details exist.
    if (isUserAuthentication()) {
        if (config.url.includes("https://astm-mcs-outbound")) {
            return config;
        }
        if (authToken) {
            if (!isOKTAStateAndNonceExists()) {
                setCookie(cookieKeysEnum['IS-OKTA-VERIFIED'], true, process.env.REACT_APP_COOKIES_EXPIRATION_TIME);
            }
            config.headers['Authorization'] = `Bearer ${authToken}`;
            if (delegation?.proxyMemberUserId) {
                config.headers['IdentityDelegationUserId'] = IdentityDelegationUserId;
            }
            return config;
        } else {
            removeCookie(cookieKeysEnum['IS-OKTA-VERIFIED']);
        }
        if (config.headers && config.headers['Public-Team-Token']) {
            config.headers['Authorization'] = `Bearer ${config.headers['Public-Team-Token']}`;
            delete config.headers['Public-Team-Token'];
            return config;
        }
        if (config.headers && config.headers['Open-Api']) {
            delete config.headers['Authorization'];
            delete config.headers['Open-Api'];
            return config;
        }

    }
    // =============================================================
    //***************START OPEN-AUTH-IMPLEMENTATION*****************
    //==============================================================
    return new Promise((resolve, reject) => {
        if (PublicUrls.find(x => config.url.match(x))) {
            config.headers.securitykey = process.env.REACT_APP_OPEN_SECURITY_KEY;
        }
        else {
            openAuthUrls.headers['Authorization'] = `Bearer ${authToken}`;
        }
        return resolve(config);
    });
    // =============================================================
    //***************START OPEN-AUTH-IMPLEMENTATION****************
    //==============================================================

}

axios.interceptors.request.use(async (config) => {
    if (window.localStorage.getItem('storeUUIDForAllApiCall') === "" || window.localStorage.getItem('storeUUIDForAllApiCall') === null) {
        window.localStorage.setItem('storeUUIDForAllApiCall', JSON.stringify({}));
    }
    let generateUUIDForApiCall = generateUUID(true);
    let storeUUIDArray = JSON.parse(window.localStorage.getItem('storeUUIDForAllApiCall'));
    let newStoreUUIDArray = { ...storeUUIDArray };
    newStoreUUIDArray[config.url] = generateUUIDForApiCall;
    window.localStorage.setItem('storeUUIDForAllApiCall', JSON.stringify(newStoreUUIDArray));
    config.headers.uniquerequestid = generateUUIDForApiCall;
    return await validateOpenAuth(config);
}, (error) => {
    datadogLogger(
        `API Server Request Error: ${error.config.url}`,
        {
            url: error.config.url,
            uniqueRequestId: error.config.headers.uniquerequestid,
            requestedData: error.config.data,
        },
        DATADOG_LOG_TYPE.error,
        {
            name: `API Server Request Error: ${error.config.url}`,
            message: error.code
        }
    );
    return Promise.reject(error);
})

axios.interceptors.response.use((response) => {
    const responseData = response.data;
    if (!responseData || !(responseData.ResponseStatus || responseData.Status || responseData.status)) {
        datadogLogger(
            `API Response Error: ${response.config.url}`,
            {
                url: response.config.url,
                uniqueRequestId: response.config.headers.uniquerequestid,
                requestedData: response.config.data,
                responseData: responseData,
                status: response.status,
                statusText: response.statusText,
            },
            DATADOG_LOG_TYPE.error,
            {
                name: `API Response Error: ${response.config.url}`
            }
        );
    }
    return response;
}, async (error) => {
    if ("OAuthError" === error.name || "AuthSdkError" === error.name) {
        datadogLogger(
            `OAuthError or AuthSdkError Error: ${error.name}`,
            {
                error,
                config: error.config,
            },
            DATADOG_LOG_TYPE.error,
            {
                error
            }
        );
        setRedirectUrl(window.location.href.replace(/.*\/\/[^\/]*/, ''));
        loginHandler();
    }
    datadogLogger(
        `API Server Response Error: ${error.config.url}`,
        {
            url: error.config.url,
            uniqueRequestId: error.config.headers.uniquerequestid,
            requestedData: error.config.data,
            responseData: error.response.data,
            code: error.code,
            message: error.message,
            status: error.response.status,
            statusText: error.response.statusText
        },
        DATADOG_LOG_TYPE.error,
        {
            name: `API Server Response Error: ${error.config.url}`,
            message: error.code
        }
    );
    return Promise.reject(error);
});

// TODO : Validate Public Page token expire then remove and Update on refresh action
export const validateOpenAPIToken = (response) => {
    if (get(response, 'data')
        && get(response, 'data.ResponseCode') === 401
        && get(response, 'status')
        && get(response, 'statusText') === 'Forbidden') {
        // To Do remove open auth token public url
        removeCookie("open-auth-token");
    }
}

export default axios;