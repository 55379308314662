import 'polyfill-array-includes';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { datadogLogs } from '@datadog/browser-logs';
import {createRoot} from 'react-dom/client';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'astm-ux-design-system/dist/bundle.css';
import store from './src/store';
import './src/assets/themes/main.css';
import App from 'components/pages/app';
import ErrorBoundary from 'components/organisms/ErrorBoundary';
import { BrowserRoutesProvider, loginHandler, OIDC, verifyMECallForSession } from './src/helpers/OktaUtility';
import './src/index.css';
import * as serviceWorker from './src/serviceWorker';
import { FlagFeaturesProvider } from './src/providers/flagFeature';
import { BannerProvider } from './src/providers/banner';

(() => {
  let isJiraEnabled = process.env.REACT_APP_JIRA_ISSUE_COLLECTOR_ENABLED;
  if (isJiraEnabled === 'true') {
    let src = process.env.REACT_APP_JIRA_ISSUE_COLLECTOR_URL;
    document.write('<script src="' + src + '"><\/script>');
  }
})();

datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: process.env.REACT_APP_DATADOG_ENV,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['error','debug'],
    beforeSend: (event) => {
        if (event.message.indexOf('Warning:') === 0) {
            return false;
        }
    }
});

// TODO : set up required props.
const OKTA_PROPS = {
  OIDC,
  loginHandler,
  verifyMECallForSession,

}

createRoot(document.getElementById('root')).render(
    <ErrorBoundary>
        <Provider store={store}>
            <BrowserRoutesProvider OIDC_CONFIG={OIDC}>
                <FlagFeaturesProvider>
                    <BannerProvider>
                        <App {...OKTA_PROPS} />
                    </BannerProvider>
                </FlagFeaturesProvider>
            </BrowserRoutesProvider>
        </Provider>
    </ErrorBoundary>
);

serviceWorker.unregister();